import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import IronhackLogo from '../assets/images/ironhack-logo.svg';
import { device } from '../constants/breakpoints';

const StyledLogo = styled(IronhackLogo)`
  width: 2em;
  height: 2em;
  margin-top: 1em;

  @media ${device.tablet} {
    width: 3em;
    height: 3em;
    margin-left: 2em;
    margin-top: 2em;
  }
`;

const Logo = (props) => {
  return <StyledLogo />;
};

export default Logo;
